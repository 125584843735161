<template>
  <v-row>
    <v-col cols="12" xl="8">
      <h3>Newsletter Sign up</h3>
      <div>
        Sign up to receive an email about all our upcoming meetings, brew days
        and events.
      </div>
      <v-form v-model="valid" ref="subscription">
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="fullName"
            :rules="nameRules"
            :counter="40"
            :disabled="submitting"
            label="Full Name"
            variant="outlined"
            density="compact"
            hide-details="auto"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            :disabled="submitting"
            label="E-mail"
            variant="outlined"
            density="compact"
            hide-details="auto"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="primary"
            :disabled="!valid || (valid && submitting)"
            @click="submitSubscription"
          >
            <v-progress-circular
              v-if="submitting"
              :size="20"
              indeterminate
              color="purple"
              class="mr-2"
            ></v-progress-circular>
            <v-icon v-if="!submitting">mdi-email-newsletter</v-icon>
            <span class="ml-2">Submit</span>
          </v-btn>
        </v-col>
      </v-form>
    </v-col>
    <v-snackbar v-model="snackbar" multi-line>
      {{ snackbarText }}

      <template v-slot:actions>
        <v-btn color="red" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>
<script>
import api from "@/api";
export default {
  name: "EmailSubscription",
  setup() {},
  data: () => ({
    valid: false,
    submitting: false,
    snackbar: false,
    snackbarText: "",
    fullName: "",
    nameRules: [
      (v) => !!v || "Full name is required",
      (v) => v.length <= 40 || "Name must be less than 40 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),
  methods: {
    async submitSubscription() {
      this.submitting = true;
      try {
        this.displayResults(
          await api.createSubscription({
            fullName: this.fullName,
            email: this.email,
          }),
        );
        this.$refs.subscription.reset();
        this.$refs.subscription.resetValidation();
      } catch (error) {
        this.displayResults("There was an error, please try again later.");
      }
      this.submitting = false;
    },
    displayResults(data) {
      this.snackbar = true;
      this.snackbarText = data.message;
    },
  },
};
</script>
