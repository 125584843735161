<template>
  <template v-for="(event, index) in events" :key="index">
    <Event v-if="index < 1" v-bind:event="event" />
  </template>
  <div class="float-right mt-4">
    <v-btn to="/events" variant="outlined" size="small" color="primary">
      View All Upcoming Events
    </v-btn>
  </div>
</template>

<script>
import { defineComponent, ref, onErrorCaptured } from "vue";
import Event from "@/components/details/Event.vue";
import api from "@/api";
import eventData from "@/test-data/events.json";
const loadEventData = async () => {
  if (process.env.NODE_ENV === "production") {
    return (await api.getEvents()).data;
  } else {
    return eventData.data;
  }
};

export default defineComponent({
  name: "HomeEventLayout",
  async setup() {
    const error = ref();

    onErrorCaptured((e) => {
      error.value = e;
    });

    const events = ref(await loadEventData());

    return { events, error };
  },
  components: {
    Event,
  },
});
</script>
