<template>
  <div class="my-4">
    <div class="date-container">
      <span class="date-text">
        {{
          new Date(
            event.start.dateTime !== undefined
              ? event.start.dateTime
              : event.start.date + "T00:00",
          ).toLocaleDateString("en-us", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        }}
      </span>
    </div>
    <div class="ml-4">
      <!-- Event Title -->
      <div class="event-title">
        {{ event.summary }}
      </div>

      <!-- Event Starting TIme -->
      <div>
        <v-icon>mdi-clock-outline</v-icon>
        <span class="ml-1">
          {{
            event.start.dateTime !== undefined
              ? new Date(event.start.dateTime)
                  .toLocaleTimeString()
                  .replace(":00", "")
              : "All Day"
          }}
        </span>
      </div>

      <!-- Event Location -->
      <div>
        <a
          :href="'https://www.google.com/maps/search/' + event.location"
          target="_map"
          class="no-link"
        >
          <v-icon>mdi-map-marker-outline</v-icon>
          <span class="ml-1">
            {{ event.location !== undefined ? event.location : "TBA" }}
          </span>
        </a>
      </div>

      <!-- Event Education Topic -->
      <div
        v-if="
          event.description !== undefined &&
          event.description.toLowerCase().includes('education')
        "
      >
        <v-icon>mdi-school-outline</v-icon>
        <span class="ml-1">
          {{ event.description.split(";")[0].split(":")[1] }}
        </span>
      </div>

      <!-- Event Zoom Link -->
      <div
        v-if="
          event.description !== undefined &&
          event.description.toLowerCase().includes('zoom')
        "
      >
        <a
          :href="'https://' + event.description.split(';')[1].split(':')[1]"
          target="_education"
          style="text-decoration: none; color: inherit"
        >
          <v-icon>mdi-laptop</v-icon>
          <span class="ml-1"> Virtual Presentation Link</span>
        </a>
      </div>

      <!-- Event Calendar Button -->
      <div class="mt-2">
        <v-btn
          variant="outlined"
          size="small"
          color="primary"
          :href="event.htmlLink"
          target="event"
        >
          <v-icon class="mr-1">mdi-calendar-plus</v-icon>
          Add to Calendar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.date-container {
  border-bottom: 1px solid rgb(17, 75, 95);
  font-size: 1.2em;
}

.date-text {
  background-color: rgb(17, 75, 95);
  color: #ffffff;
  padding: 4px 8px;
  display: inline-block;
}

.event-title {
  font-size: 1.5em;
  text-transform: capitalize;
  font-variant: small-caps;
}

.no-link {
  text-decoration: none;
  color: inherit;
}
</style>

<script>
export default {
  name: "EventDetails",
  props: ["event"],
  data: () => ({}),
};
</script>
