<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{
        content ? `Inland Empire Brewers | ${content}` : `Inland Empire Brewers`
      }}
    </template>
    <template v-slot:description="{ content }">
      {{ content }}
    </template>
    <template v-slot:keywords="{ content }">
      {{ content }}
    </template>
  </metainfo>
  <v-app>
    <Navigation></Navigation>
    <v-main>
      <router-view />
    </v-main>
    <Bottom class="mt-4"></Bottom>
  </v-app>
</template>

<style>
#app * {
  font-family: Lato, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
  font-variant: small-caps;
  font-family: Lora, sans-serif;
}

p {
  margin-bottom: 16px;
  margin-top: 16px;
}

ul {
  padding-left: 2.5em;
}

.img-logo {
  filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
  max-height: 300px;
}

.img-malts {
  background-image: linear-gradient(
      rgba(17, 75, 95, 0.45),
      rgba(17, 75, 95, 0.45)
    ),
    url(@/assets/hops-header.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-header {
  padding: 0.75rem 1.25rem;
  text-align: center;
  margin-bottom: 0;
  color: #fff;
  background-color: #114b5f;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.item-subtext {
  margin-left: 1.5rem;
}

.img-members {
  background-image: linear-gradient(
      rgba(17, 75, 95, 0.6),
      rgba(17, 75, 95, 0.6)
    ),
    url(@/assets/2022FestMembership.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img-taps {
  background-image: linear-gradient(
      rgba(17, 75, 95, 0.6),
      rgba(17, 75, 95, 0.6)
    ),
    url(@/assets/taps-lineup.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes pulse-bg {
  0% {
    background-color: #ddd;
  }

  50% {
    background-color: #d0d0d0;
  }

  100% {
    background-color: #ddd;
  }
}

.loading {
  animation: pulse-bg 1s infinite;
}
</style>

<script>
import Navigation from "@/components/layout/Navigation.vue";
import Bottom from "@/components/layout/Footer.vue";

export default {
  name: "App",
  components: {
    Navigation: Navigation,
    Bottom: Bottom,
  },
  data: () => ({
    //
  }),
};
</script>
