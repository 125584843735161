<template>
  <v-carousel height="600" cycle hide-delimiter-background show-arrows="hover">
    <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" cover>
      <v-container fill-height fluid pa-0 ma-0 pb-3>
        <v-layout fill-height align-end pb-4 mb-4>
          <v-flex xs12>
            <v-card color="white" class="pa-2">
              <span class="headline black--text" v-text="item.text"> </span>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "PictureSlider",
  setup() {},
  data() {
    return {
      items: [
        {
          src: require("@/assets/slider/2024-fest-1.jpg"),
          text: "2024 Southern California Homebrewers Festival",
        },
        {
          src: require("@/assets/slider/2024-fest-2.jpg"),
          text: "2024 Southern California Homebrewers Festival",
        },
        {
          src: require("@/assets/slider/2024-fest-4.jpg"),
          text: "2024 Southern California Homebrewers Festival",
        },
        {
          src: require("@/assets/slider/2024-fest-5.jpg"),
          text: "2024 Southern California Homebrewers Festival",
        },
        {
          src: require("@/assets/slider/2024-fest-6.jpg"),
          text: "2024 Southern California Homebrewers Festival",
        },
        {
          src: require("@/assets/slider/2024-fest-7.jpg"),
          text: "2024 Southern California Homebrewers Festival",
        },
        {
          src: require("@/assets/slider/2024-fest-8.jpg"),
          text: "2024 Southern California Homebrewers Festival",
        },
        {
          src: require("@/assets/slider/2024-fest-9.jpg"),
          text: "2024 Southern California Homebrewers Festival",
        },
        {
          src: require("@/assets/slider/2024-fest-10.jpg"),
          text: "2024 Southern California Homebrewers Festival",
        },
        {
          src: require("@/assets/slider/2024-route30-entire-group.jpg"),
          text: "2024 Wort Share and Collab Brew Session at Route 30",
        },
        {
          src: require("@/assets/slider/2024-route30-group.jpg"),
          text: "2024 Wort Share and Collab Brew Session at Route 30",
        },
        {
          src: require("@/assets/slider/2024-route30-jason.jpg"),
          text: "2024 Wort Share and Collab Brew Session at Route 30",
        },
        {
          src: require("@/assets/slider/2024-route30-tim-mash.jpg"),
          text: "2024 Wort Share and Collab Brew Session at Route 30",
        },
        {
          src: require("@/assets/slider/2023-ritual-wort-share.jpg"),
          text: "2023 Wort Share at Ritual Brewing Company",
        },
        {
          src: require("@/assets/slider/2023-lake-arrowhead.jpg"),
          text: "2023 Lake Arrowhead Charity Event",
        },
        {
          src: require("@/assets/slider/2022-fest.jpg"),
          text: "2022 Southern California Homebrewers Festival",
        },
        {
          src: require("@/assets/slider/2023-competition.jpg"),
          text: "2023 Southern California Regional Homebrew Championship",
        },
        {
          src: require("@/assets/slider/2023-homebrew-con.jpg"),
          text: "2023 Homebrew Con Club Night",
        },
        {
          src: require("@/assets/slider/2022-amber-waves-of-grains.jpg"),
          text: "Amber Waves of Grains Charity Event",
        },
        {
          src: require("@/assets/slider/2023-pub-crawl-rip-current.jpg"),
          text: "2023 Pub Crawl at Rip Current Brewing",
        },
      ],
    };
  },
  components: {},
};
</script>
